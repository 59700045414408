import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Form from "../components/form"

export const query = graphql`
  query {
    allFile(filter: {extension: {eq: "md"}, name: {eq: "service"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              intro {
                text
                title
                button {
                  text
                  link
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function Service(props) {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <Head title={data.title} description={data.intro.text} robots="index, follow" />
      <Intro subtitle={data.title} title={data.intro.title} titleHeading="h1" text={data.intro.text} button={data.intro.button} />
      <Form />
    </Layout>
  )
}
