import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function Form() {
  const [selectColor, setSelectColor] = useState("text-gray");
  const [placeholder, setPlaceholder] = useState("Weitere Angaben");
  
  function handleChange(event) {
    setSelectColor("text-petrol")

    event.target.value === "Ich möchte ein Folgerezept beantragen" ?
      setPlaceholder("Weitere Angaben (z.B. Medikament, Dosis, Packungsgröße)") : setPlaceholder("Weitere Angaben")
  }

  return (
    <div className="py-16 sm:py-24 lg:py-32 mt-16 sm:mt-24 lg:mt-32 bg-green-lighter">

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center mb-8 md:mb-12 lg:mb-16">
        <div className="block text-4xl !leading-[1.1] font-serif text-petrol sm:text-5xl lg:text-6xl break-words hyphens-auto">Kontaktformular</div>
      </div>

      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:px-8">
        <form name="Rezepte und Überweisungen" method="post" action="/vielen-dank" data-netlify="true" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <input type="hidden" name="form-name" value="Rezepte und Überweisungen" />
          <input type="hidden" name="subject" value="Anfrage für Rezept oder Überweisung" />
          <input
            type="text"
            name="Vorname"
            placeholder="Vorname"
            autoComplete="given-name"
            required
            className="py-3 px-4 block w-full bg-white border-none focus:ring-0 rounded-md text-petrol placeholder:text-gray"/>
          <input
            type="text"
            name="Nachname"
            placeholder="Nachname"
            autoComplete="family-name"
            required
            className="py-3 px-4 block w-full bg-white border-none focus:ring-0 rounded-md text-petrol placeholder:text-gray"/>
          <div className="sm:col-span-2">
            <input
              name="Geburtsdatum*"
              type="text"
              placeholder="Geburtsdatum"
              autoComplete="bday"
              required
              className="py-3 px-4 block w-full bg-white border-none focus:ring-0 rounded-md text-petrol placeholder:text-gray"
            />
          </div>
          <div className="sm:col-span-2">
            <input
              name="Email"
              type="email"
              placeholder="E-Mail"
              autoComplete="email"
              required
              className="py-3 px-4 block w-full bg-white border-none focus:ring-0 rounded-md text-petrol placeholder:text-gray"
            />
          </div>
          <div className="sm:col-span-2">
            <select name="Betreff" defaultValue="" required className={`py-3 px-4 block w-full bg-white border-none focus:ring-0 rounded-md ${selectColor}`} onChange={handleChange}>
              <option value="" disabled>Betreff auswählen</option>
              <option value="Ich möchte ein Folgerezept beantragen">Ich möchte ein Folgerezept beantragen</option>
              <option value="Ich möchte eine Überweisung beantragen">Ich möchte eine Überweisung beantragen</option>
            </select>
          </div>
          <div className="sm:col-span-2">
            <textarea
              name="Nachricht"
              placeholder={placeholder}
              required
              rows={4}
              className="py-3 px-4 block w-full bg-white border-none focus:ring-0 rounded-md text-petrol placeholder:text-gray"
            />
          </div>
          <div className="sm:col-span-2">
            <div className="flex items-start">
              <input className="form-check-input appearance-none !border-none h-4 w-4 rounded-sm bg-white checked:!bg-petrol !outline-none focus:!ring-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="terms" name="terms" required />
              <label className="form-check-label inline-block text-petrol" htmlFor="terms">
              Ich habe die{" "}
                  <AniLink paintDrip to="/datenschutz" duration={1} hex="F5DCAD" className="underline">Datenschutz­bestimmungen</AniLink> gelesen und akzeptiere diese.
              </label>
            </div>
          </div>
          <div className="sm:col-span-2">
            <button
              type="submit"
              className="px-8 py-3 md:px-10 md:py-4 border border-transparent text-base font-semibold rounded-full text-white bg-petrol hover:bg-green whitespace-nowrap transition-colors">
              Senden
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
